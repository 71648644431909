import { Turnstile } from '@marsidev/react-turnstile';
import { useMask } from '@react-input/mask';
import { generateLeadEmailURL } from 'app/actions/affiliate.actions';
import { siteVerification } from 'app/actions/cloudflare.actions';
import { getTurnstileKey } from 'app/actions/turnstile.actions';
import { Datepicker, Label, Select, TextInput, Textarea } from 'flowbite-react';
import { useEffect, useState } from 'react';

interface DynamicForm {
  [key: string]: string;
}

export const ContactForm = () => {
  const inputRef = useMask({
    mask: '(___) ___-____',
    replacement: { _: /\d/ }
  });

  const [userChoice, setUserChoice] = useState<string>('0');
  const [bestTime, setBestTime] = useState<string>('1');
  const [bestDate, setBestDate] = useState<string>(new Date().toISOString());
  const [turnstileToken, setTurnstileToken] = useState<string>('');
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [turnstileKey, setTurnstileKey] = useState<string | null>(null);

  const [form, setForm] = useState<DynamicForm>({
    gaMedium: 'referral',
    gaSource: '',
    gaContent: '/',
    gaClientId: '970385394.1727054325',
    gaCampaign: '(referral)'
  });

  useEffect(() => {
    setTurnstileKey(getTurnstileKey());

    setForm({ ...form, gaSource: window.location.host });
  }, []);

  async function createQuote(formData: FormData) {
    if (!turnstileToken) {
      setError('Please verify you are not a robot');
      return;
    }

    try {
      const status = await siteVerification(turnstileToken);

      if (status === 200) {
        const rawFormData = {
          firstName: formData.get('firstName'),
          lastName: formData.get('lastName'),
          email: formData.get('email'),
          phone: formData.get('phone'),
          subject: userChoice,
          question: formData.get('question'),
          bestTime,
          bestDate
        };

        let toEmail;
        switch (rawFormData.subject) {
          case '1':
            toEmail = 'partnerships@frontpoint.com';
            break;
          case '2':
            toEmail = 'sales@frontpointsecurity.com';
            break;
          case '3':
            toEmail = 'permits@frontpointsecurity.com';
            break;
          case '4':
            toEmail = 'supportTicketQueue@frontpoint.com';
            break;
          case '5':
            toEmail = 'hr@frontpointsecurity.com';
            break;
          case '6':
            toEmail = 'supportcancellations@frontpoint.com';
            break;
          case '7':
            toEmail = 'webfeedback@frontpointsecurity.com';
            break;
        }

        const payload = {
          toAddress: toEmail,
          firstName: rawFormData.firstName,
          lastName: rawFormData.lastName,
          phoneNumber: rawFormData.phone,
          subject: userChoice,
          body: rawFormData.question,
          contactWindow: '',
          contactDate: ''
        };

        if (userChoice === '6') {
          payload.contactWindow = bestTime;
          payload.contactDate = bestDate;
        }

        const response = await generateLeadEmailURL(payload);
        console.log('Response:', response);
        if (response === 200) {
          setShowSuccess(true);
        }
        setUserChoice('');
        setBestTime('');
        setBestDate('');
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>
      <form
        className="flex w-full flex-col gap-3 [&_label]:!text-[16px] [&_label]:!font-normal [&_label]:text-[#3b3b3b]"
        action={createQuote}
      >
        <div>
          <div className="block">
            <Label htmlFor="firstName" value="First Name" />
          </div>
          <TextInput name="firstName" type="text" required shadow />
        </div>
        <div>
          <div className="block">
            <Label htmlFor="lastName" value="Last Name" />
          </div>
          <TextInput name="lastName" type="text" required shadow />
        </div>

        <div>
          <div className="block">
            <Label htmlFor="email" value="Email" />
          </div>
          <TextInput name="email" type="email" required shadow />
        </div>

        <div>
          <div className="block">
            <Label htmlFor="phone" value="Phone Number" />
          </div>
          <TextInput
            pattern="\([0-9]{3}\) [0-9]{3}-[0-9]{4}"
            ref={inputRef}
            name="phone"
            type="text"
            required
            shadow
          />
        </div>

        <div>
          <div className="block">
            <Label htmlFor="subject">Subject (choose one)</Label>
          </div>
          <Select
            id="subject"
            onChange={(choice) => {
              setUserChoice(choice.target.value);
            }}
          >
            <option value="0">None</option>
            <option value="1">Frontpoint Partnerships</option>
            <option value="2">Product Sales</option>
            <option value="3">Permits</option>
            <option value="4">ProductSupport</option>
            <option value="5">Recruitment, Talent Management, &amp; HR</option>
            <option value="6">Account Change or Cancellation Request*</option>
            <option value="7">Feedback</option>
          </Select>
        </div>

        <div>
          <div className="block">
            <Label htmlFor="question">
              Question or Request
              {userChoice === '6' && <span className="ml-1 text-red-500">(Required)</span>}
            </Label>
          </div>
          <Textarea name="question" rows={4} required={userChoice === '6'} shadow />
        </div>

        {userChoice === '6' && (
          <>
            <div className="mb-1 mt-3 text-red-600">
              *In order to complete an Account Update or Request Cancellation, a member of our
              Customer Loyalty Team will need to speak with you to verify and confirm the account
              change. Please provide the best time to contact you and a detailed description of the
              requested account change.
            </div>
            <div>
              <div className="block">
                <Label htmlFor="bestTime" value="Best Time to contact me:" />
              </div>
              <Select
                id="bestTime"
                required
                onChange={(choice) => {
                  setBestTime(choice.target.value);
                }}
              >
                <option value="1">Morning</option>
                <option value="2">Noon</option>
                <option value="3">Evening</option>
              </Select>
            </div>

            <div>
              <div className="block">
                <Label htmlFor="bestDate" value="Pick a Date from the below options:" />
              </div>
              <Datepicker
                id="bestDate"
                minDate={new Date()}
                required
                weekStart={1}
                onSelectedDateChanged={(d) => {
                  setBestDate(d.toISOString());
                }}
              />
            </div>
          </>
        )}

        <div className="mt-2 flex w-full flex-1 justify-center">
          {turnstileKey && (
            <Turnstile
              siteKey={turnstileKey}
              onError={(e) => {
                setTurnstileToken('');
              }}
              onExpire={(e) => {
                setTurnstileToken('');
              }}
              onSuccess={(e) => {
                setTurnstileToken(e);
              }}
            />
          )}
        </div>
        <div className="mt-2 flex w-full flex-1 justify-center">
          <button
            disabled={!turnstileToken}
            type="submit"
            className="flex w-full items-center justify-center rounded-md border-2 border-fp-green bg-white p-2 font-bold text-fp-green"
          >
            Contact Me
          </button>
        </div>
      </form>
      {showSuccess && (
        <div className="mt-2 bg-fp-green py-2 text-center text-white">Email sent successfully!</div>
      )}
    </>
  );
};
