'use client'
import { getBundleByHandle } from 'app/actions/bundles.actions';
import { ShopifyBundle } from 'lib/shopify/types';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { cn } from 'utils/cn';

export function ProductBox({builderProductHandle, variant}: {builderProductHandle: string, variant: number}) {
    if (!builderProductHandle){
            return (
            <div>
                Please Select a product
            </div>
        );
    }
    const [product, setProduct] = useState<ShopifyBundle | undefined>(undefined);
    const [productVariant, setProductVariant] = useState<number>(variant);
    
    useEffect(() => {
        const getProductData = async (handle: string) => {
            const productData = await getBundleByHandle(handle);
            setProduct(productData);
        }
        getProductData(builderProductHandle);
        
    }, [builderProductHandle]);  

    useEffect(() => {
        setProductVariant(variant);
    }, [variant]);

  if (!product){
    return <div>Loading ...</div>
  }

  const isBundle = product.bundleComponents.nodes.length > 0
  const productBundles = product.bundleComponents.nodes.map(({quantity,componentProduct}) => {return {quantity, ...componentProduct}});
  const availableCamera = productBundles.filter(product => product.title.toLowerCase().includes('camera'))
  const productVariants = {1: '', 2:  'p-4 bg-[#5ABB62]'}[productVariant];
  const cameraOptions = !!availableCamera.length ? availableCamera.map(camera=> `${camera.quantity > 1 ? camera.quantity : ''} ${camera.title}`).join(' + ') : 'No Camera option';
  return (
    <div className='flex flex-col justify-center items-center w-full'>
        <div className={cn('rounded-md w-full', productVariants)}>
            <picture>
                <source
                    media="(max-width: 640px)"
                    srcSet={`${product.featuredImage.url}&width=300&crop=center 1x, ${product.featuredImage.url}&width=600&crop=center 2x`}
                />
                <source
                    media="(max-width: 1024px)"
                    srcSet={`${product.featuredImage.url}&width=500&crop=center 1x, ${product.featuredImage.url}&width=1000&crop=center 2x`}
                />
                <source
                    media="(min-width: 1025px)"
                    srcSet={`${product.featuredImage.url}&width=700&crop=center 1x, ${product.featuredImage.url}&width=1400&crop=center 2x`}
                />
                <img 
                    src={product.featuredImage.url}
                    alt={`${product.title} Feature Image`}
                    className='w-full h-auto rounded-xl'
                    loading="lazy"
                />
            </picture>
        </div>
        {isBundle && <div className='text-base mt-4 text-center'>{cameraOptions}</div>}
        <Link href={`/shop/${product.handle}`} className='mt-4 rounded-2xl bg-[#5ABB62] px-6 py-[6px] border-[#5ABB62] border-[1px] w-fit text-white hover:text-[#5ABB62] hover:bg-white'>
            Buy Now
        </Link>
    </div>
  );
}