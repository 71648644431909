import { Turnstile } from '@marsidev/react-turnstile';
import { generateConsumerAffiliateFormURL } from 'app/actions/affiliate.actions';
import { siteVerification } from 'app/actions/cloudflare.actions';
import { getTurnstileKey } from 'app/actions/turnstile.actions';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { TextInput } from 'flowbite-react';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';

dayjs.extend(utc);

interface DynamicForm {
  [key: string]: string;
}

export const AffiliateForm = () => {
  const [turnstileToken, setTurnstileToken] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [turnstileKey, setTurnstileKey] = useState<string | null>(null);
  const [showMessage, setShowMessage] = useState<boolean>(false);

  const [form, setForm] = useState<DynamicForm>({
    gaMedium: 'referral',
    gaSource: '',
    gaContent: '/',
    gaClientId: '970385394.1727054325',
    gaCampaign: '(referral)'
  });

  useEffect(() => {
    setTurnstileKey(getTurnstileKey());

    setForm({ ...form, gaSource: window.location.host });
  }, []);
  const router = useRouter();
  async function createQuote(formData: FormData) {
    if (!turnstileToken) {
      setError('Please verify you are not a robot');
      return;
    }

    try {
      const status = await siteVerification(turnstileToken);

      if (status === 200) {
        const rawFormData = {
          firstName: formData.get('firstName'),
          lastName: formData.get('lastName'),
          email: formData.get('email'),
          company: formData.get('company'),
          social: formData.get('social')
        };

        const payload = {
          firstName: rawFormData.firstName,
          lastName: rawFormData.lastName,
          companyName: rawFormData.company,
          email: rawFormData.email,
          submissionInfo: rawFormData.social,
          isApproved: null,
          submissionDate: dayjs().utc().format()
        };

        const response: any = await generateConsumerAffiliateFormURL(payload);

        if (response === 200) {
          setShowMessage(true);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <form
      className="affiliate-form flex w-full flex-col gap-3 [&_label]:!text-[16px] [&_label]:!font-normal [&_label]:text-[#3b3b3b]"
      action={createQuote}
    >
      <div>
        <TextInput name="firstName" type="text" placeholder="First Name" required shadow />
      </div>
      <div>
        <TextInput name="lastName" type="text" placeholder="Last Name" required shadow />
      </div>

      <div>
        <TextInput name="email" type="email" placeholder="Email Address" shadow />
      </div>

      <div>
        <TextInput name="social" type="text" placeholder="Social Media or Website URL" shadow />
      </div>

      <div>
        <TextInput name="company" type="text" placeholder="Company Name" required shadow />
      </div>
      <div className="mt-2 flex w-full flex-1 flex-col justify-center text-center">
        {!showMessage && (
          <>
            <button
              type="submit"
              className="flex w-full items-center justify-center rounded-md border-2 border-fp-green bg-fp-green p-2 font-bold text-white hover:bg-white hover:text-fp-green"
              disabled={!turnstileKey}
            >
              GET STARTED TODAY!
            </button>
          </>
        )}

        {showMessage && (
          <>
            <button
              className="flex w-full items-center justify-center rounded-md border-2 border-fp-green bg-fp-green p-2 font-bold text-white"
              disabled
            >
              THANK YOU FOR YOUR INTEREST IN BECOMING A FRONTPOINT AFFILIATE
            </button>
          </>
        )}

        <a
          href="https://help.frontpointsecurity.com/en/my-frontpoint-account/CAP_Termsandconditions"
          className="font-400 mt-1 text-[14px] underline"
        >
          CLICK HERE FOR TERMS AND CONDITIONS
        </a>
      </div>
      <div className="mt-2 flex w-full flex-1 flex-col justify-center text-center">
        <a
          href="https://suppliers.tipalti.com/FrontPoint/Account/Login"
          target="_blank"
          className="flex w-full items-center justify-center rounded-md border-2 border-black bg-black p-2 font-bold text-white hover:bg-white hover:text-black"
        >
          RETURNING USER LOGIN
        </a>
        <a
          href="https://suppliers.tipalti.com/FrontPoint/Account/PasswordRecovery"
          target="_blank"
          className="font-400 mt-1 text-[14px] underline"
        >
          FORGOT PASSWORD
        </a>
      </div>
      <div className="mt-2 flex w-full flex-1 justify-center">
        {turnstileKey && (
          <Turnstile
            siteKey={turnstileKey}
            onError={(e) => {
              setTurnstileToken('');
            }}
            onExpire={(e) => {
              setTurnstileToken('');
            }}
            onSuccess={(e) => {
              setTurnstileToken(e);
            }}
          />
        )}
      </div>
    </form>
  );
};
