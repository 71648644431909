'use client';
import { RequestQuoteWizardForm } from 'components/requestQuote/RequestQuoteWizardForm';
import { RequestQuoteWizardStep } from 'components/requestQuote/RequestQuoteWizardStep';
import { useEffect, useState } from 'react';

interface RequestQuoteWizardProps {
  formId?: string;
  variant: string;
  steps?: RequestQuoteWizardStep[];
  sourceKey: string;
}

type RequestQuoteWizardStep = {
  stepId: string;
  variant: string;
  title?: string;
  description?: string;
  buttons: Array<{
    value: string;
    text: string;
  }>;
};

interface DynamicForm {
  [key: string]: string;
}

export function RequestQuoteWizard(props: RequestQuoteWizardProps) {
  const { variant, steps, sourceKey } = props;
  const [form, setForm] = useState<DynamicForm>({
    gaMedium: 'referral',
    gaSource:  '',
    gaContent: '/',
    gaClientId: '970385394.1727054325',
    gaCampaign: '(referral)'
  });

  useEffect(() => {
    setForm({...form, gaSource: window.location.host});
  },[]);
  
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const onSetForm = (data: DynamicForm, changeStep: boolean) => {
    setForm(data);
    if (changeStep) {
      if (steps && currentStepIndex < steps.length) {
        setCurrentStepIndex(currentStepIndex + 1);
      }
    }
  };

  const onchangeStep = () => {
    if (steps && steps.length) {
      const currentStep = steps[currentStepIndex];
      const formValue: any = currentStep ? form[currentStep.stepId] : null;

      if (formValue && currentStepIndex < steps.length) {
        setCurrentStepIndex(currentStepIndex + 1);
      }
    }
  };

  return (
    <div>
      {!!steps?.length && (
        <div className="pt-6">
          {steps.map((step, i) => {
            return (
              <div className={`${currentStepIndex === i ? 'flex' : 'hidden'}`}>
                <RequestQuoteWizardStep
                  stepId={step.stepId}
                  variant={variant}
                  title={step.title}
                  description={step.description}
                  buttons={step.buttons}
                  form={form}
                  setForm={onSetForm}
                />
              </div>
            );
          })}
          <div className={`${currentStepIndex === steps.length ? 'flex flex-col' : 'hidden'}`}>
            <RequestQuoteWizardForm sourceKey={sourceKey} variant={variant} form={form} setForm={setForm} />
          </div>

          {variant === 'default' && (
            <>
              {currentStepIndex !== steps.length && (
                <div className="px-20">
                  <div className="mt-10 flex justify-between">
                    <span />
                    <p>
                      {currentStepIndex + 1}/{steps.length + 1}
                    </p>
                    <button className="text-fp-green" onClick={onchangeStep}>
                      Next
                    </button>
                  </div>
                </div>
              )}
            </>
          )}

          {variant === 'lifecare' && (
            <>
              {currentStepIndex + 1 === steps.length + 1 && (
                <div
                  className="px-4 py-8"
                  style={{
                    width: '350px',
                    top: '1vh',
                    right: 'calc(50vw - 350px)',
                    position: 'absolute',
                    background: '#fff',
                    borderRadius: '30px',
                    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)'
                  }}
                >
                  <p className="text-bold text-[18px]">
                    {' '}
                    We're almost done building your personalized quote!
                  </p>
                  <p className="text-bold mt-4 text-[18px]">You Will Get:</p>
                  <p className="mt-4 text-[14px]">Equipment Recommendations</p>
                  <p className="mt-2 text-[14px]">Monitoring Costs</p>
                  <p className="mt-2 text-[14px]">Description of Product Features</p>
                  <p className="mt-2 text-[14px]">Limited Time Special Offer</p>
                </div>
              )}
              <div className="px-20">
                <div className="mt-4 flex justify-center text-[12px] font-bold text-fp-green">
                  <p>
                    Step {currentStepIndex + 1} Of {steps.length + 1}
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}
