import { useMask } from '@react-input/mask';
import { generatePartnersURL } from 'app/actions/affiliate.actions';
import { Label, TextInput } from 'flowbite-react';

interface LensFormProps {
  sourceKey: string;
}

export const LensForm = (props: LensFormProps) => {
  const { sourceKey } = props;

  const inputRef = useMask({
    mask: '(___) ___-____',
    replacement: { _: /\d/ }
  });

  async function createQuote(formData: FormData) {
    try {
        const rawFormData = {
            name: formData.get('name'),
            phone: formData.get('phone'),
            email: formData.get('email')
          };

          const nameList = rawFormData?.name?.toString()?.split(' ');
  
          const payload = {
            firstName: nameList?.[0] ||'',
            lastName: nameList?.[1] ||'',
            phone: rawFormData.phone,
            email: rawFormData.email
          };
  
          const leadURL = await generatePartnersURL(sourceKey);

          if (leadURL) {
            const response = await fetch(leadURL, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(payload)
            });
            if (response.status === 200) {
            }
          }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div className="flex w-full">
      <form
        className="lens-form flex w-full gap-8 [&_label]:!text-[14px] [&_label]:!font-normal [&_label]:text-[#5abb62] items-center"
        action={createQuote}
      >
        <div className="flex-1">
          <div className="block">
            <Label htmlFor="name" value="Name (First and Last)*" />
          </div>
          <TextInput placeholder="Name" name="name" type="text" required />
        </div>

        <div className="flex-1">
          <div className="block">
            <Label htmlFor="phone" value="Phone Number*" />
          </div>
          <TextInput pattern="\([0-9]{3}\) [0-9]{3}-[0-9]{4}" ref={inputRef} placeholder="Number" name="phone" type="text" required />
        </div>

        <div className="flex-1">
          <div className="block">
            <Label htmlFor="email" value="Email*" />
          </div>
          <TextInput placeholder="Enter your email address here" name="email" type="email" required />
        </div>

        <div className="flex justify-center mt-2">
          <button
            type="submit"
            className="h-fit py-1 px-4 flex items-center justify-center rounded-full border-2 border-[#008eaa] bg-white  font-bold text-[#008eaa]"
          >
            GET A QUOTE
          </button>
        </div>
      </form>
    </div>
  );
};
